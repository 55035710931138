import { useEffect, useState } from "react";
import { CURRENT_SITE } from "../data/constantData";
import { getAllReleases } from "../services/release.service";

const months = [
  "ינואר",
  "פברואר",
  "מרץ",
  "אפריל",
  "מאי",
  "יוני",
  "יולי",
  "אוגוסט",
  "ספטמבר",
  "אוקטובר",
  "נובמבר",
  "דצמבר",
];

const byMonth = function (a, b) {
  return months.indexOf(a._month) - months.indexOf(b._month);
};

export default function useSortedReleases() {
  const [releases, setReleases] = useState();

  const [sortedReleases, setSortedReleases] = useState();

  useEffect(() => {
    getAllReleases().then((data) => {
      // כדי לקבל רק לוחות חודשיים אתה צריך לקרוא ל-API עם releaseType=612db1618ad9893533b85baf
      // כדי לקבל תוכניות - releaseType=612db12b8ad9893533b85bae
      const monthlyReleasesOnly = data
        .filter(
          (release) => release?.releaseType === CURRENT_SITE.releaseTypes.monthlyBoard // hot CURRENT_SITE.releaseTypes.monthlyBoard = "612db1618ad9893533b85baf"
        )
        .map((release) => {
          release._month = (release?.releaseData?.title || "").split(" ");
          release._year = Number(release._month.pop());
          if (release._year?.toString?.().length === 2) release._year = +('20' + release._year);
          release._month = release._month.join(" ");
          return release;
        });
      // const monthlyReleasesOnly = data;
      setReleases(monthlyReleasesOnly);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let years = [];
    if (!releases) return;
    releases.map((release) => {
      years.push(release._year ? release._year : "");
      return release;
    });
    years = [...new Set(years)].sort().reverse();

    const _sortedReleases = [
      ...years.map((year) => {
        return {
          year,
          releases: releases
            .filter(
              (release) =>
                release._year === year || (!release._year && year === "")
            )
            .sort(byMonth),
        };
      }),
    ];
    console.log(_sortedReleases);
    setSortedReleases(_sortedReleases);
  }, [releases]);

  return sortedReleases;
}
