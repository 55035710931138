import React, { useMemo } from "react";
import { Redirect } from "react-router-dom";
import useSortedReleases from "../hooks/useSortedReleases";
import NotFound from "./NotFound";
import { getQueryParam } from "./helpers/utils.service";

export default function RedirectToLastRelease() {
  const sortedReleases = useSortedReleases();

  const LastReleaseID = useMemo(
    () => sortedReleases?.[0]?.releases?.slice(-1)?.[0]?._id,
    [sortedReleases]
  );

  const releaseInQuery = getQueryParam('releaseId');
  const subDomInQuery = getQueryParam('view');
  const releaseIdToUse = releaseInQuery || LastReleaseID;

  const actualTo = subDomInQuery? `/${subDomInQuery}/${releaseInQuery}` : `/release/${releaseIdToUse}`;
  console.log('REDIRECTING!', actualTo);

  return !sortedReleases ? (
    <main className="wrap">
      <h4>מעבירים לחודש האחרון...</h4>
      <div>מחפש את הפריט האחרון ששוחרר</div>
    </main>
  ) : !releaseIdToUse ? (
    <NotFound />
  ) : (
    <Redirect to={actualTo} />
  );
  // JSON.stringify(sortedReleases?.[0]?.releases?.pop())
}
