import React from "react";
import { constantData, CURRENT_SITE } from "../data/constantData";
import useRelease from "../hooks/useRelease";
import ContactRow from "./sub-components/ContactRow";
// import forceDownload from "./helpers/forceDownload";

export default function Contact() {
  useRelease();
  return (
    <main className="contact-page">
      <div className="contact-types">
        {CURRENT_SITE.slug === "hot" &&
          constantData.contactTypes.map((contactType, key) => (
            <div className="contact-type" key={key}>
              {(contactType?.contacts && (contactType?.contacts.filter(c => c.name).length > 0)) && (
                <>
                  {/* <hr /> */}
                  {contactType.name && <h5>{contactType.name}</h5>}
                  <div className="contacts">
                    {contactType.contacts.filter(c => c.name).map((contact, key) => (
                      <ContactRow contact={contact} key={key} />
                    ))}
                  </div>
                </>
              )}
            </div>
          ))}
        {CURRENT_SITE.slug === "hot8" && (
          <div>
            {/* <p>
              {" "}
              <strong>שרון בלום | מנהלת יחסי ציבור</strong>
               | נייד: 052-4588955
              | <a href="mailto:sharon@hscc.co.il">sharon@hscc.co.il </a>
            </p> */}

            {/* <p>
              {" "}
              <a href="https://maps.google.com/?q=%D7%90%D7%99%D7%99%D7%96%D7%99%D7%A7+%D7%A8%D7%9E%D7%91%D7%94+1,+%D7%92%D7%A0%D7%99+%D7%94%D7%AA%D7%A2%D7%A8%D7%95%D7%9B%D7%94,+%D7%AA%D7%9C+%D7%90%D7%91%D7%99%D7%91-%D7%99%D7%A4%D7%95&entry=gmail&source=g">
                אייזיק רמבה 1, גני התערוכה, תל אביב-יפו
              </a>{" "}
              משרד: 03-6421195 שלוחה 230
            </p> */}
          </div>
        )}
      </div>
      {/* <h3 className="logos-title">לוגואים להורדה</h3> */}
      <div className="logos">
        {CURRENT_SITE.logos.map((logo, key) => (
          <a
            href={`/images/contact-downloads/${logo}`}
            key={key}
            download={logo}
            // onClick={(e) => {
            //   e.preventDefault();
            //   forceDownload(`/images/contact-downloads/${logo}`, logo);
            // }}
          >
            <img src={`/images/contact-downloads/${logo}`} alt="" />
          </a>
        ))}
      </div>
    </main>
  );
}
