import React, { useState } from "react";
import Embed from "react-embed";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

export default function AiringPlayButton(props) {
  const video = props.video;
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  return (
    <div className="play">
      <button
        type="button"
        className="button play-button"
        onClick={() => setOpen((o) => !o)}
      >
        <i className="far fa-play-circle"></i>
      </button>
      <Popup open={open} closeOnDocumentClick onClose={closeModal}>
        <div className="modal">
          <button className="close" onClick={closeModal}>
            &times;
          </button>
          <div className="video-wrapper">
            <Embed url={video.src || video.link}/>
          </div>
        </div>
      </Popup>
    </div>
  );
}
