// import { logToServer } from "../../services/release.service";

// import { fixFileSrcToThumbnail } from "./utils.service.js";

export default function forceDownload(url, fileName, forcedName = '') {
  return downloadImg(url, forcedName);
  // return new Promise((resolve, reject) => {
  //   const xhr = new XMLHttpRequest();
  //   xhr.open("GET", url, true);
  //   xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
  //   xhr.responseType = "blob";
  //   xhr.onload = function () {
  //     const urlCreator = window.URL || window.webkitURL;
  //     const imageUrl = urlCreator.createObjectURL(this.response);
  //     const tag = document.createElement("a");
  //     tag.href = imageUrl;
  //     if (fileName.includes("undefined")) fileName = undefined; // sometimes fileName is string "undefined." weird...
  //     let fallbackFileName;
  //     try {
  //       fallbackFileName = decodeURI(/(?:\.([^.]+))?$/.exec(url)[1]).trim();
  //     } catch (error) {
  //       fallbackFileName = "image";
  //     }
  //     tag.download = (forcedName || fileName || fallbackFileName || 'image') + ".jpg";
  //     document.body.appendChild(tag);
  //     tag.click();
  //     document.body.removeChild(tag);
  //     resolve();
  //   };
  //   xhr.onerror = (event) => {
  //     const errorItem = new Error(xhr.statusText || 'UNKNOWN ERROR');
  //     errorItem.event = event;
  //     reject(errorItem);
  //     console.log("Error while downloading:", xhr.statusText);
  //     console.log(event);
  //     console.log(xhr);
  //     console.log(xhr.status);
  //     console.log(xhr.statusText);
  //     console.log(xhr.response);
  //     const reader = new FileReader();
  //     reader.onload = function(e) {
  //       console.log(e.target.result);
  //     }
  //     reader.readAsText(xhr.response);
  //     reader.readAsText(xhr.responseText);
  //     reader.readAsText(xhr.responseXML);
  //     logToServer('error', 'Error: cant download img', '', { error: { err: errorItem, msg: xhr.statusText, status: xhr.status, imgUrl: url, fileName: forcedName } });
  //   }
  //   xhr.send();
  // });
}



async function downloadImg(url, fileName) {
  // url = fixFileSrcToThumbnail(url);
  const elLink = document.createElement('a');
  const dataUrl = await imgSrcToDataUrl(url);
  elLink.href = dataUrl;
  console.log('GOT DATA URL!', fileName, dataUrl)
  elLink.download = fileName + '.jpg';
  elLink.target = "_blank";
  document.body.appendChild(elLink);
  elLink.click();
  document.body.removeChild(elLink);
}
function imgSrcToDataUrl(url) {
  return new Promise((resolve, reject) => {
    const imgEl = new Image();
    imgEl.crossOrigin = 'anonymous';
    imgEl.src = url;
    imgEl.onload = function() {
      try {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.height = imgEl.naturalHeight;
        canvas.width = imgEl.naturalWidth;
        ctx.drawImage(imgEl, 0, 0);
        const dataUrl = canvas.toDataURL();
        resolve(dataUrl);
      } catch(e) {
        reject(e);
      }
    }
    imgEl.onerror = function(e) {
      reject(e);
    }
  });
}