
// export function fixFileSrcToThumbnail(file) {
//   // return url;
//   return file.src.replace('megaphonecs.s3.amazonaws.com/uploads', 'images.megaphon.co.il');
// }

export function getQueryParam(param) {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get(param);
}


export function fixFileSrcToThumbnail(file = {}) {
  return file.src; // handled in back;
  // console.log('src debug', file, _getFileSrc(file, '', {baseApiUrl: `https://megaphon.co.il/api`,}, true, false), _getFileSrc(file, '', {baseApiUrl: `https://megaphon.co.il/api`,}, true, false) === file?.src);
  // return _getFileSrc(file, '', {baseApiUrl: `https://megaphon.co.il/api`,}, true, false);
}
export function fixVideoSrcToThumbnail(file = {}, organizationId = '') {
  return file.src; // handled in back;
  // return _getFileSrc(file, organizationId, {baseApiUrl: `https://megaphon.co.il/api`,}, false, true);
}


// const BUCCET_URL = 'megaphonecs.s3.amazonaws.com';
// const UPLOADS_FOLDER_URL = `${BUCCET_URL}/uploads`;
// const CLOUDFRONT_URL = 'images.megaphon.co.il';





// make sure its the same in hot app;
// function getCloudFrontUrlForFile(url = '') {
//   return url.replace(UPLOADS_FOLDER_URL, CLOUDFRONT_URL);
// }

// function _getFileSrc(file, organizationId, config, allowSrc = false, isVid = false) {
//   if (!file) return '';
//   if (typeof file === 'string') return getCloudFrontUrlForFile(file);
//   if (!file?.fileId) return getCloudFrontUrlForFile(file.src);
//   if (file?.src) {
//     if (allowSrc) return getCloudFrontUrlForFile(file.src); // TODODODO;
//     const fileKeyData = getFileKey(file.src);
//     if (!fileKeyData.key) return file.src
//   }
//   if (isVid) return `${config.baseApiUrl}/file/watch/${organizationId}/${file.fileId}`
//   return `${config.baseApiUrl}/file/${file.fileId}`;
// }
// function getFileKey(fileSrc = '') {
//   const fileDomains = [UPLOADS_FOLDER_URL, CLOUDFRONT_URL, BUCCET_URL];
//   const usedDomain = fileDomains.find(dom => fileSrc.includes(dom));
//   // if (!usedDomain) return '';
//   const key = usedDomain? fileSrc.split(usedDomain)?.[1]?.split('/').filter(Boolean).join('/') : '';
//   return {
//     usedDomain,
//     key,
//     fullKey: key && (usedDomain !== BUCCET_URL) ? `uploads/${key || ''}` : key || '',
//   };
// }