import React from "react";
import Show from "./Show";

export default function SingleShow(props) {
  return (
    <>
      <Show {...props} single />
    </>
  );
}
