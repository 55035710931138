import React from "react";

import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

import { Link } from "react-router-dom";

import useRelease from "../hooks/useRelease";
import { stripHtmlWhiteSpace } from "./helpers/stripHtmlWhiteSpace";

export default function Slider() {
  const release = useRelease();

  const properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    prevArrow: (
      <div className="arrow prev-arrow">
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 30.32 67.57"
        >
          <defs>
            <style>
              .cls-1
              {`{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:4px;}`}
            </style>
          </defs>
          <polyline className="cls-1" points="28.32 2 2 33.79 28.32 65.57" />
        </svg>
      </div>
    ),
    nextArrow: (
      <div className="arrow next-arrow">
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 30.32 67.57"
        >
          <defs>
            <style>
              .cls-1
              {`{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:4px;}`}
            </style>
          </defs>
          <polyline className="cls-1" points="2 65.57 28.32 33.79 2 2" />
        </svg>
      </div>
    ),
  };

  return (
    <section className="slider">
      <div className="inside">
        {(release?.releaseData?.childrenReleases || release?.releaseData?.shows) && (
          <Slide easing="ease" {...properties}>
            {(release?.releaseData?.childrenReleases || release?.releaseData?.shows)?.map((show, key) => (
              <div key={key} className="each-slide">
                <Link to={`/show/${show._id}`}>
                  {/* <img
                    src={show?.releaseData?.mainImage?.[0]?.src}
                    alt={show?.releaseData?.mainImage?.[0]?.title}
                  /> */}
                  <img
                    src={
                      show?.releaseData?.backgroundImage?.[0]?.src ||
                      show?.releaseData?.mainImage?.src ||
                      show?.releaseData?.mainImage?.[0]?.src
                    }
                    alt={
                      show?.releaseData?.backgroundImage?.[0]?.title ||
                      show?.releaseData?.mainImage?.title ||
                      show?.releaseData?.mainImage?.[0]?.title
                    }
                  />
                  <div className="release-details">
                    <h3>{show?.releaseData?.title}</h3>
                    <h4>{show?.releaseData?.subtitle}</h4>
                    {show?.releaseData?.broadcastTimesDescription && (
                      <>
                        <div
                          className="broadcast-times-description"
                          dangerouslySetInnerHTML={{
                            __html: stripHtmlWhiteSpace(
                              show?.releaseData?.broadcastTimesDescription
                            ),
                          }}
                        ></div>
                        <hr />
                      </>
                    )}
                  </div>
                </Link>
              </div>
            ))}
          </Slide>
        )}
      </div>
    </section>
  );
}
