import React from "react";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <main className="wrap">
      <h4>הדף לא נמצא</h4>
      <Link className="button" to="/archive">
        לארכיון
      </Link>
    </main>
  );
}
