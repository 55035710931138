import React from "react";
import useSortedReleases from "../hooks/useSortedReleases";


export default function Archive() {

  const sortedReleases = useSortedReleases();

  return (
    <main className="archive-page">
      <div className="wrap">
        {!sortedReleases ? (
          <h3>טוען...</h3>
        ) : (
        !sortedReleases.length ? <h2>אין תוכן להציג בארכיון</h2> : sortedReleases.map((year, key) => (
            <div className="year" key={key}>
              <h2>{year.year || "[ללא שנה]"}</h2>
              <div className="releases">
                {year.releases.map((release, key) => {
                  return (
                    <div key={key} className="post">
                      <a
                        href={`/release/${release?._id}`}
                        title={release?.releaseData?.subtitle}
                      >
                        {(release?.releaseData?.mainImage?.[0]?.src || release?.releaseData?.mainImage?.src) && (
                          <div className="img-wrap">
                            <img
                              src={release?.releaseData?.mainImage?.[0]?.src || release?.releaseData?.mainImage?.src}
                              alt=""
                            />
                          </div>
                        )}
                        <span className="month">
                          {release?._month || "[ללא שם]"}
                        </span>
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          ))
        )}
      </div>
    </main>
  );
}
