const licenseTypeMap = {
  1: "מקור",
  2: "רכש",
  3: "רכש ישראלי",
  4: "hbo"
};
export function getLicenseType(id) {
  return licenseTypeMap[id] || "";
}


// 0: {option: '1', label: 'מקור'}
// 1: {option: '2', label: 'רכש'}
export const licenseTypeArray = [...Object.keys(licenseTypeMap).map((key) => {return {value: key, label: licenseTypeMap[key]}})];
