import React from "react";
import { Link } from "react-router-dom";
import useRelease from "../hooks/useRelease";
import AiringPlayButton from "./sub-components/AiringPlayButton";

export default function Airing() {
  const release = useRelease();
  return (
    <main className="airing-page">
      <div className="smallwrap">
        <div className="airing-table">
          <div className="header row">
            <div>תכנית</div>
            <div>ימים</div>
            <div>תאריך</div>
            <div>שעות</div>
            <div>תמונות</div>
            <div>לצפייה</div>
          </div>
          {(release?.releaseData?.shows || release?.releaseData?.childrenReleases)?.map((show, key) => (
            <div className="row show" key={key}>
              <Link to={`/show/${show?._id}`} className="showText">
                <div className="inside">
                  {show?.releaseData?.broadcastTimes?.map(
                    (broadcastTime, key) => (
                      <div key={key}>
                        <div>
                          {key === 0 ? (
                            <strong>{show?.releaseData?.title}</strong>
                          ) : (
                            "שידור נוסף"
                          )}
                        </div>
                        {show?.releaseData?.broadcastTimes.length - 1 !==
                          key && <hr />}
                      </div>
                    )
                  )}
                </div>
              </Link>
              <div className="dayText">
                <div className="inside">
                  {show?.releaseData?.broadcastTimes?.map(
                    (broadcastTime, key) => (
                      <div key={key}>
                        <div>{broadcastTime?.dayText || <>&nbsp;</>}</div>
                        {show?.releaseData?.broadcastTimes.length - 1 !==
                          key && <hr />}
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className="dateText">
                <div className="inside">
                  {show?.releaseData?.broadcastTimes?.map(
                    (broadcastTime, key) => (
                      <div key={key}>
                        <div>{broadcastTime?.dateText || <>&nbsp;</>}</div>
                        {show?.releaseData?.broadcastTimes.length - 1 !==
                          key && <hr />}
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className="hourText">
                <div className="inside">
                  {show?.releaseData?.broadcastTimes?.map(
                    (broadcastTime, key) => (
                      <div key={key}>
                        <div>{broadcastTime?.hourText || <>&nbsp;</>}</div>
                        {show?.releaseData?.broadcastTimes.length - 1 !==
                          key && <hr />}
                      </div>
                    )
                  )}
                </div>
              </div>
              <div>
                <Link to={`/show/${show?._id}/pictures`}>
                  <img
                    src={
                      show?.releaseData?.trailerImage?.[0]?.src ||
                      show?.releaseData?.mainImage?.[0]?.src ||
                      show?.releaseData?.mainImage?.src
                    }
                    alt={
                      show?.releaseData?.trailerImage?.[0]?.title ||
                      show?.releaseData?.mainImage?.[0]?.title ||
                      show?.releaseData?.mainImage?.title
                    }
                  />
                </Link>
              </div>
              <div>
                {show.releaseData?.videos && show.releaseData?.videos[0] && (
                  <AiringPlayButton video={show.releaseData?.videos[0]} />
                )}
              </div>
              {/* <div>{JSON.stringify(show)}</div> */}
            </div>
          ))}
        </div>
      </div>
    </main>
  );
}
