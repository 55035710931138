import React, {  } from "react";
import { Link } from "react-router-dom";
import useRelease from "../hooks/useRelease";
import { stripHtmlWhiteSpace } from "./helpers/stripHtmlWhiteSpace";
import AiringPlayButton from "./sub-components/AiringPlayButton";

export default function Summary() {
  const release = useRelease();
  return (
    <main className="smallwrap summary-page">
      {(release?.releaseData?.childrenReleases || release?.releaseData?.shows)?.map((show, key) => (
        <article key={key} className="show">
          <div className="header-wrap">
            <Link to={`/show/${show._id}`}>
              <header>
                <h2>
                  {show?.releaseData?.title}
                  {show?.releaseData?.title_english && (
                    <>
                      <br />
                      {show?.releaseData?.title_english}
                    </>
                  )}
                </h2>
                <h3 className="subtitle">{show?.releaseData?.subtitle}</h3>
                <hr />
                <div className="broadcast-times-description"
                  dangerouslySetInnerHTML={{
                    __html: stripHtmlWhiteSpace(show?.releaseData?.broadcastTimesDescription),
                  }}
                ></div>
              </header>
            </Link>
            {(show?.releaseData?.mainImage?.[0]?.src || show?.releaseData?.mainImage?.src) && (
              <div className="video">
                {show?.releaseData?.videos && show?.releaseData?.videos[0] && <AiringPlayButton video={show?.releaseData?.videos[0]} />}
                <img
                  src={show?.releaseData.mainImage?.[0]?.src || show?.releaseData?.mainImage?.src}
                  alt={show?.releaseData.mainImage?.[0]?.title || show?.releaseData?.mainImage?.title}
                />
              </div>
            )}
          </div>
          <div className="content">
            <div
              className="description"
              dangerouslySetInnerHTML={{
                __html: stripHtmlWhiteSpace(show?.releaseData?.content),
              }}
            ></div>
          </div>
        </article>
      ))}
    </main>
  );
}
