import { useContext, useEffect } from "react";
import { getRelease } from "../services/release.service";
import { ReleaseContext } from "../context/ReleaseContext";
import { useParams } from "react-router";
import usePreview from "./usePreview";

export default function useRelease() {
  const preview = usePreview();
  const { releaseId } = useParams();
  // const releaseId = "615b09db25a07eb7191332c0";

  const [release, setRelease] = useContext(ReleaseContext);

  useEffect(() => {
    if (releaseId) {
      getRelease(releaseId).then((data) => {
        setRelease(preview ? { ...data.preview, _id: data._id } : data);
      });
    } else {
    }
  }, [setRelease, releaseId, preview]);

  return release;
}
