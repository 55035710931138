import React from "react";
import { Link } from "react-router-dom";
import useRelease from "../hooks/useRelease";
import useSearch from "../hooks/useSearch";

export default function Search() {
  useRelease();
  const [searchTerm, setSearchTerm, results, loading] = useSearch();

  return (
    <main>
      <section className="series">
        <div className="search-wrap">
          <div className="wrap">
            <input
              autoFocus
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="הקלידו כאן..."
            />
          </div>
        </div>
        {searchTerm &&
          (loading ? (
            <div className="wrap">
              <h5>מחפש...</h5>
            </div>
          ) : !results.length ? (
            <div className="wrap">
              <h5>לא נמצא</h5>
            </div>
          ) : (
            <div className="inside">
              {results?.map((show, key) => (
                <div className="serie" key={key}>
                  <Link to={`/show/${show._id}`}>
                    <div className="img-wrap">
                      {(show?.releaseData?.mainImage?.[0]?.src || show?.releaseData?.mainImage?.src) && (
                        <img
                          src={show?.releaseData?.mainImage?.[0]?.src || show?.releaseData?.mainImage?.src}
                          alt={show.allText}
                        />
                      )}
                    </div>
                    <div className="data">
                      <div className="inside">
                        <h3>{show?.releaseData?.title}</h3>
                        <h4>{show?.releaseData?.subtitle}</h4>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          ))}
      </section>
    </main>
  );
}
