import React, { useEffect, useState } from "react";
import Embed from "react-embed";
import { Link } from "react-router-dom";
import usePreview from "../hooks/usePreview";
import useRelease from "../hooks/useRelease";
import { getShow, logToServer } from "../services/release.service";
import { stripHtmlWhiteSpace, htmlStrToText } from "./helpers/stripHtmlWhiteSpace";
import Contact from "./sub-components/ContactRow";
import Credits from "./sub-components/Credits";
import forceDownload from "../components/helpers/forceDownload";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { fixFileSrcToThumbnail } from "./helpers/utils.service.js";

export default function Show(props) {
  const { showId, page } = props.match.params;
  const preview = usePreview();

  const [show, setShow] = useState();

  const preLink = "show";
  // const preLink = props.single ? "single" : "show";

  useEffect(() => {
    getShow(showId).then((data) => {
      setShow(preview ? { ...data.preview, _id: data._id } : data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const release = useRelease();

  const getImgDownloadData = (img) => {
    let { title = '', src = '' } = img;
    src = fixFileSrcToThumbnail(img);
    title = `${title}.${src
      .split(".")
      .pop()
      .split(" ")[0]
      .replace("jpf", "jpg")}`;
      
    title = title.replace('.file', '.jpg');
    return { title, src };
  }

  const [isLoading, setIsLoading] = useState(false);

  const doDownload = async (...params) => {
    try {
      setIsLoading(true);
      await forceDownload(...params);
      console.log('DOWNLOAD DONE');
    } catch(e) {
      alert('Error: Cant download image');
      console.log('ERROR:: CANT DOWNLOAD IMAGE');
      console.error(e);
      logToServer('error', 'Error: cant download img', '', { error: { err: e, msg: `${e.name}:: ${e.message} | ${e?.stack?.toString?.() || ''}` } });
    }
    setIsLoading(false);
  }


  const broadcastTimeSection = (possibleDescHtml = false) => (
    possibleDescHtml && show?.releaseData?.broadcastTimesDescription ? <div
      className="broadcast-times-description"
      dangerouslySetInnerHTML={{
        __html: stripHtmlWhiteSpace(
          show?.releaseData?.broadcastTimesDescription
        ),
      }}
    ></div> 
    : <div className="time">
      {!show?.releaseData?.broadcastTimes ? (
        <h5>אין שעות שידור</h5>
      ) : (
        <table className="pretty-table">
          <thead>
            <tr>
              <td>תוכנית</td>
              <td>ימים</td>
              <td>תאריך</td>
              <td>שעות</td>
            </tr>
          </thead>
          <tbody>
            {show?.releaseData?.broadcastTimes.map(
              (broadcastTime, key) => (
                <tr key={key}>
                  <td>{show?.releaseData?.title}</td>
                  <td>{broadcastTime.dayText}</td>
                  <td>{broadcastTime.dateText}</td>
                  <td>{broadcastTime.hourText}</td>
                </tr>
              )
            )}
          </tbody>
        </table>
      )}
    </div>)

  return (
    <main className="wrap">
      { (isLoading) && <div className="loader-container">
        {/* <div className="loader">LOADING</div> */}
        <img className="loader" src="/images/loader.gif" alt="Loading.."/>
        <div className="blure"></div>
      </div> }
      <section className="show">
        {!show ? (
          <h5>טוען...</h5>
        ) : !show.releaseData ? (
          <h5>תכנית לא נמצאה</h5>
        ) : (
          <div className="inside">
            <nav>
              <ul>
                <li className={!page ? "active" : ""}>
                  <Link to={`/${preLink}/${showId}/`}>תקציר</Link>
                </li>
                {show?.releaseData?.videos?.filter(c => c.src || c.link).length > 0 && (
                  <li className={`${page === "play" ? "active" : ""} watch`}>
                    <Link to={`/${preLink}/${showId}/play`}>לצפייה</Link>
                  </li>
                )}
                {(show?.releaseData?.imageGallery || show?.releaseData?.images)?.filter(c => c.src).length !== 0 && (
                  <li className={page === "pictures" ? "active" : ""}>
                    <Link to={`/${preLink}/${showId}/pictures`}>תמונות</Link>
                  </li>
                )}
                {htmlStrToText(show?.releaseData?.festivals).trim() && (
                  <li className={page === "festivals" ? "active" : ""}>
                    <Link to={`/${preLink}/${showId}/festivals`}>פסטיבלים</Link>
                  </li>
                )}
                {show?.releaseData?.links &&
                  show?.releaseData?.links?.filter(c => c.title).length !== 0 && (
                    <li className={page === "links" ? "active" : ""}>
                      <Link to={`/${preLink}/${showId}/links`}>קישורים</Link>
                    </li>
                  )}
                {show?.releaseData?.reviews?.filter?.(c => c.title)?.length > 0 && (
                  <li className={page === "reviews" ? "active" : ""}>
                    <Link to={`/${preLink}/${showId}/reviews`}>ביקורות</Link>
                  </li>
                )}
                {show?.releaseData?.broadcastTimes?.filter(c => c.dayText).length > 0 && (
                  <li className={page === "time" ? "active" : ""}>
                    <Link to={`/${preLink}/${showId}/time`}>שעות שידור</Link>
                  </li>
                )}
              </ul>
            </nav>
            <hr className="vertical" />
            <div className="content">
              {!page ? (
                <div className="summary">
                  <img
                    src={fixFileSrcToThumbnail(show?.releaseData?.mainImage?.[0]?.src ? show?.releaseData?.mainImage?.[0] : show?.releaseData?.mainImage)}
                    className="main-thumbnail"
                    alt={show?.releaseData?.mainImage?.[0]?.title || show?.releaseData?.mainImage?.title}
                  />
                  <h2 style={ {'fontSize': '2em'} }>
                    {show?.releaseData?.title}
                    {show?.releaseData?.title_english && (
                      <>
                        <br />
                        {show?.releaseData?.title_english}
                      </>
                    )}
                  </h2>

                  {show?.releaseData?.subtitle && (
                    <h3 className="subtitle">{show?.releaseData?.subtitle}</h3>
                  )}
                  <div
                    className="description"
                    dangerouslySetInnerHTML={{
                      __html: stripHtmlWhiteSpace(show?.releaseData?.content),
                    }}
                  ></div>
                  {(show?.releaseData?.broadcastTimesDescription ||
                    show?.releaseData?.logos) && <hr />}
                  <div className="extra-data">
                    <div
                      className="broadcast-times-description"
                      dangerouslySetInnerHTML={{
                        __html: stripHtmlWhiteSpace(
                          show?.releaseData?.broadcastTimesDescription
                        ),
                      }}
                    ></div>
                    {show?.releaseData?.logos && (
                      <div className="logos">
                        {show?.releaseData?.logos.map((logo, key) => (
                          <div className="logo" key={key}>
                            <img src={logo.src} alt={logo.title} />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  {(show?.releaseData?.contacts && (show?.releaseData?.contacts.filter(c => c.name).length) > 0) && (
                    <>
                      <hr />
                      <div className="contacts">
                        {show?.releaseData?.contacts.filter(c => c.name).map((contact, key) => (
                          <Contact contact={contact} key={key} />
                        ))}
                      </div>
                    </>
                  )}

                  {/* {broadcastTimeSection(true)} */}

                  <hr />
                  <footer>
                    {release && (
                      <Link target="_blank" to={`/show/${show?._id}`}>
                        קישור ישיר
                      </Link>
                    )}
                    {(release && (show?.releaseData?.comFile?.[0]?.src || show?.releaseData?.comFile?.src)) && " | "}
                    {(show?.releaseData?.comFile?.[0]?.src || show?.releaseData?.comFile?.src) && (
                      <>
                        <a
                          target="_blank"
                          href={show?.releaseData?.comFile?.[0]?.src || show?.releaseData?.comFile?.src}
                          title={show?.releaseData?.comFile?.[0]?.title || show?.releaseData?.comFile?.title}
                          rel="noreferrer"
                          download={show?.releaseData?.comFile?.[0]?.title || show?.releaseData?.comFile?.title}
                        >
                          קומניקט
                        </a>
                      </>
                    )}
                  </footer>
                </div>
              ) : page === "play" ? (
                <div className="play">
                  {!show?.releaseData ||
                  !show?.releaseData?.videos ||
                  !show?.releaseData?.videos?.length ? (
                    <h5>אין סרטונים</h5>
                  ) : (
                    show?.releaseData?.videos.map((video, key) => (
                      <div key={key}>
                        <h4>{video.title}</h4>
                        <div className="video-wrapper">
                          <Embed url={video.src || video.link} title={video.title}></Embed>
                        </div>
                      </div>
                    ))
                  )}
                  <Credits>{show?.releaseData?.galleryCredit}</Credits>
                </div>
              ) : page === "pictures" ? (
                <div className="pictures-part">
                  {!show?.releaseData ||
                  !(show?.releaseData?.imageGallery?.length || show?.releaseData?.images?.length) ? (
                    <h5>אין תמונות</h5>
                  ) : (
                    <>
                      <div className="pictures">
                        {(show?.releaseData?.imageGallery || show?.releaseData?.images).filter(Boolean).map((img, key) => (
                          <div className="picture" key={key}>
                            <div className="inside">
                              <Zoom
                                closeText="הקטנת תמונה"
                                openText="הגדלת תמונה"
                                overlayBgColorEnd="rgba(0,0,0,0.77)"
                                transitionDuration="500"
                                zoomMargin="50"
                                wrapStyle={{ width: "100%" }}
                              >
                                <img
                                  src={fixFileSrcToThumbnail(img)}
                                  alt={img.title}
                                />
                              </Zoom>
                              <div className="links">
                                <a
                                  href={fixFileSrcToThumbnail(img)}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  פתח
                                </a>
                                <a
                                  href={getImgDownloadData(img).src}
                                  download={getImgDownloadData(img).title}
                                  // data-download-link={img.src.replace('https://megaphonecs.s3.amazonaws.com/uploads', 'https://images.megaphon.co.il')}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    doDownload(
                                      getImgDownloadData(img).src,
                                      getImgDownloadData(img).title,
                                      show.releaseData.title
                                    );
                                  }}
                                >
                                  הורד
                                </a>
                              </div>
                            </div>
                            <p>{img.title}</p>
                            <Credits>{img?.credit}</Credits>
                          </div>
                        ))}
                      </div>
                      <Credits>{show?.releaseData?.galleryCredit}</Credits>
                    </>
                  )}
                </div>
              ) : page === "festivals" ? (
                <div className="festivals">
                  {!show?.releaseData?.festivals ? (
                    <h5>אין מידע</h5>
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: show?.releaseData?.festivals,
                      }}
                    ></div>
                  )}
                </div>
              ) : page === "links" ? (
                <div className="links">
                  {!show?.releaseData?.links ? (
                    <h5>אין קישורים</h5>
                  ) : (
                    show?.releaseData?.links?.map((link, key) => (
                      <div className="link" key={key}>
                        <a href={link.src || link.link} target="_blank" rel="noreferrer">
                          {link.title || "[לינק]"}
                        </a>
                      </div>
                    ))
                  )}
                </div>
              ) : page === "reviews" ? (
                <div className="reviews">
                  {!show?.releaseData?.reviews ? (
                    <h5>אין ביקורות</h5>
                  ) : (
                    show?.releaseData?.reviews.map((review, key) => (
                      <div className="review" key={key}>
                        <a href={review.src || review.link} target="_blank" rel="noreferrer">
                          {review.title}
                        </a>
                      </div>
                    ))
                  )}
                </div>
              ) : page === "time" ? (
                broadcastTimeSection()
              ) : page === "full" ? (
                <div className="full">המידע של הפרקים המלאים חסר ב־API</div>
              ) : (
                <h2>עמוד לא נמצא</h2>
              )}
              {/* <pre dir="ltr">{JSON.stringify(show, null, 4)}</pre> */}
            </div>
          </div>
        )}
      </section>
    </main>
  );
}
