import React from "react";

export default function ContactRow(props) {
  const { contact } = props;
  return ( 
    contact.name &&
    <div className="contact">
      <strong className="nameAndRole">
        {contact.name}:
        {contact.role && (
          <>
            {" "}
            {/* |  */}
            <span className="role">{contact.role}</span>
          </>
        )}
      </strong>
      {contact.phone && (
        <>
          {" "}
          {/* |{" "} */}
          <a href={`tel:${contact.phone}`} className="phone">
            {contact.phone}
          </a>
        </>
      )}
      {contact.email && (
        <>
          {" "}
          {/* |{" "} */}
          <a href={`mailto:${contact.email}`} className="email">
            {contact.email}
          </a>
        </>
      )}
      <br />
      {contact.address && (
        <a
          href={`https://maps.google.com/?q=${encodeURIComponent(
            contact.address
          )}&entry=gmail&source=g`}
          className="address"
          target="_blank"
          rel="noreferrer"
        >
          {contact.address}
        </a>
      )}
      {contact.additional && (
        <>
          {" "}
          | <span className="additional">{contact.additional}</span>
        </>
      )}
    </div>
  );
}
