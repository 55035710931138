import { useMemo, useState } from 'react'
import { searchFor } from '../services/release.service';

export default function useSearch() {
    const [searchTerm, setSearchTerm] = useState("");
    const [results, setResults] = useState();
    const [loading, setLoading] = useState();
  
    useMemo(async () => {
      setLoading(true);
      const results = await searchFor(searchTerm);
      setResults(results);
      setLoading(false);
      return results;
    }, [searchTerm]);

    return [searchTerm, setSearchTerm, results, loading];
}
