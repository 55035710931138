import React from 'react'
import Series from './Series'
import Slider from './Slider'

export default function Home() {
    return (
        <div className="home">
            <Slider />
            <Series />
        </div>
    )
}
