import React from "react";
import { CURRENT_SITE } from "../../data/constantData";

export default function Logo() {
  return (
    <section className="logo">
      <h1>
        <img src={`/sites/${CURRENT_SITE.slug}/favicon-white.png`} alt={CURRENT_SITE.name} />
      </h1>
    </section>
  );
}
