import React from "react";
import { NavLink } from "react-router-dom";
import { CURRENT_SITE } from "../data/constantData";
import useRelease from "../hooks/useRelease";
import Logo from "./sub-components/Logo";

export default function Header() {
  const release = useRelease();
  const releaseId = release?._id;

  let title1 = (release?.releaseData?.title || "").split(" ");
  let title2 = title1.pop();
  title1 = title1.join(" ");

  const releaseMonth = title1;

  return (
    <header id="header" className="header">
      {releaseId ? (
        <NavLink to={`/release/${releaseId}`} className="logo-link">
          <Logo />
        </NavLink>
      ) : (
        <NavLink to={`/`} className="logo-link">
          <Logo />
        </NavLink>
      )}
      {releaseId ? (
        <nav style={ {minHeight:'100px'} }>
          <ul>
            <li>
              <NavLink
                to={`/release/${releaseId}/premieres`}
                activeClassName="is-active"
              >
                בכורות {releaseMonth || "החודש"}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/release/${releaseId}/summary`}
                activeClassName="is-active"
              >
                תקצירי החודש
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/release/${releaseId}/airing`}
                activeClassName="is-active"
              >
                לוח שידורים
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/release/${releaseId}/contact`}
                activeClassName="is-active"
              >
                צור קשר
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/release/${releaseId}/archive`}
                activeClassName="is-active"
              >
                ארכיון
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/release/${releaseId}/search`}
                activeClassName="is-active"
              >
                חיפוש
              </NavLink>
            </li>
          </ul>
        </nav>
      ) : (
        <nav style={ {minHeight:'100px'} }>
          <ul>
            {/* <li>
              <NavLink to={`/archive`} activeClassName="is-active">
                ארכיון
              </NavLink>
            </li>
            <li>
              <NavLink to={`/search`} activeClassName="is-active">
                חיפוש
              </NavLink>
            </li>
            <li>
              <NavLink to={`/contact`} activeClassName="is-active">
                צור קשר
              </NavLink>
            </li> */}
          </ul>
        </nav>
      )}
      <div className="left-side">
        <div className="social-icons">
          {CURRENT_SITE.socialLinks?.facebook && (
            <a
              href={CURRENT_SITE.socialLinks.facebook}
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-facebook" alt="facebook"></i>
            </a>
          )}
          {CURRENT_SITE.socialLinks?.instagram && (
            <a
              href={CURRENT_SITE.socialLinks.instagram}
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-instagram" alt="instagram"></i>
            </a>
          )}
          {CURRENT_SITE.socialLinks?.youtube && (
            <a
              href={CURRENT_SITE.socialLinks.youtube}
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-youtube" alt="twitter"></i>
            </a>
          )}
          {CURRENT_SITE.socialLinks?.twitter && (
            <a
              href={CURRENT_SITE.socialLinks.twitter}
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-twitter" alt="twitter"></i>
            </a>
          )}
        </div>
        {(title1 || title2) && (
          <div className="name">
            <div>
              <span className="month">{title1}</span>{" "}
              <span className="year">{title2}</span>
            </div>
          </div>
        )}
        {/* <NavLink to={`/release/${releaseId}/search`} activeClassName="is-active"><div className="search-button"></div></NavLink> */}
      </div>
    </header>
  );
}
