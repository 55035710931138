import { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import "./scss/main.scss";
import { ReleaseContext } from "./context/ReleaseContext";

import Header from "./components/Header";
import Series from "./components/Series";
import Show from "./components/Show";
import SingleShow from "./components/SingleShow";
import Archive from "./components/Archive";
import Contact from "./components/Contact";
import Search from "./components/Search";
import Airing from "./components/Airing";
import Summary from "./components/Summary";
import Home from "./components/Home";
import usePreview from "./hooks/usePreview";
import RedirectToLastRelease from "./components/RedirectToLastRelease";
import NotFound from "./components/NotFound";
import {Helmet} from "react-helmet";
import { CURRENT_SITE } from "./data/constantData";

function App() {
  const [release, setRelease] = useState();

  const preview = usePreview();

  document.body.className = '';
  document.body.classList.add(`theme-${CURRENT_SITE.slug}`);

  return (
    <div
      id="container"
      className={`${preview ? "is-preview" : ""} ${
        release ? "release-found" : "release-not-found"
      }`}
    >
      <ReleaseContext.Provider value={[release, setRelease]}>
        <Helmet>
          <title>{CURRENT_SITE.name}</title>
          <link
            rel="icon"
            type="image/png"
            href={`/sites/${CURRENT_SITE.slug}/favicon.png`}
          />
          <link
            rel="apple-touch-icon"
            href={`/sites/${CURRENT_SITE.slug}/favicon.png`}
          />
        </Helmet>
        {/* <HashRouter basename="/#"> */}
        <Router>
          <Header></Header>

          <Switch>
            <Route path="/release/:releaseId" component={Home} exact />
            <Route path="/release/:releaseId/premieres" component={Series} />
            <Route path="/search" component={Search} />
            <Route path="/release/:releaseId/search" component={Search} />
            <Route path="/release/:releaseId/summary" component={Summary} />
            <Route path="/release/:releaseId/airing" component={Airing} />
            <Route path="/contact" component={Contact} />
            <Route path="/release/:releaseId/contact" component={Contact} />
            <Route path="/release/:releaseId/archive" component={Archive} />
            <Route
              path="/release/:releaseId/show/:showId/:page/"
              component={Show}
            />
            <Route path="/release/:releaseId/show/:showId" component={Show} />
            <Route path="/show/:showId/:page/" component={SingleShow} />
            <Route path="/show/:showId" component={SingleShow} />
            <Route path="/release/:releaseId/show/">
              <Redirect to="/" />
            </Route>
            <Route path="/archive/" component={Archive} />
            <Route path="/" component={RedirectToLastRelease} />
            <Route path="*" component={NotFound} />
          </Switch>

          {preview && <div className="preview-warning">תצוגה מקדימה</div>}
          {/* </HashRouter> */}
        </Router>
      </ReleaseContext.Provider>
    </div>
  );
}

export default App;
