import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { licenseTypeArray } from "../fields/licenseType.js";
import { getShowType } from "../fields/showType.js";
import useRelease from "../hooks/useRelease.js";
import useSearch from "../hooks/useSearch.js";
import { stripHtmlWhiteSpace } from "./helpers/stripHtmlWhiteSpace.js";
import SideLabel from "./SideLabel.js";
import { fixFileSrcToThumbnail } from "./helpers/utils.service.js";

export default function Series() {
  const release = useRelease();

  const [licenseType, setLicenseType] = useState("");
  const [orderBy, setOrderBy] = useState("");

  const [searchTerm, setSearchTerm, results, loading] = useSearch();

  const shows = useMemo(() => {
    let _shows = release?.releaseData?.childrenReleases || release?.releaseData?.shows;
    if (licenseType)
      _shows = _shows.filter(
        (show) => show?.releaseData?.licenseType === licenseType
      );
    if (orderBy === "abc")
      _shows = _shows.slice().sort((a, b) => {
        if (a.releaseData?.title < b.releaseData?.title) return -1;
        if (a.releaseData?.title > b.releaseData?.title) return 1;
        return 0;
      });
    if (orderBy === "date")
      _shows = _shows.slice().sort((a, b) => {
        const A = a?.releaseData?.broadcastTimes?.[0]?.dateText;
        const B = b?.releaseData?.broadcastTimes?.[0]?.dateText;

        if (A < B) return -1;
        if (A > B) return 1;
        return 0;
      });
    return _shows;
  }, [release, licenseType, orderBy]);

  return (
    <main>
      <section className="series wrap">
        {/* <h2 className="section-title">תכניות בערוץ HOT 8</h2> */}
        <div className="filters">
          <div className="start">
            <select
              value={licenseType}
              onChange={(e) => setLicenseType(e.target.value)}
            >
              <option value="">רכש / מקור</option>
              {licenseTypeArray?.map((showType, key) => (
                <option key={key} value={showType.value}>
                  {/*  selected={showType === showType.value} */}
                  {showType.label}
                </option>
              ))}
            </select>
            {/* {licenseType} */}
          </div>
          <div className="end">
            <div className="search">
              <i className="fas fa-search"></i>
              <input
                type="text"
                placeholder="חיפוש..."
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="orderby">
              <i className="fas fa-filter"></i>
              <button
                onClick={() => setOrderBy("date")}
                className={orderBy === "date" ? "active" : ""}
              >
                תאריך
              </button>
              <button
                onClick={() => setOrderBy("abc")}
                className={orderBy === "abc" ? "active" : ""}
              >
                תכנית
              </button>
            </div>
          </div>
        </div>

        {searchTerm ? (
          loading ? (
            <div className="wrap">
              <h5>מחפש...</h5>
            </div>
          ) : !results.length ? (
            <div className="wrap">
              <h5>לא נמצא</h5>
            </div>
          ) : (
            <div className="inside">
              {results?.map((show, key) => (
                <div className="serie" key={key}>
                  <Link to={`/show/${show._id}`}>
                    <div className="img-wrap">
                      {(show?.releaseData?.mainImage?.[0]?.src || show?.releaseData?.mainImage?.src) && (
                        <img
                          src={fixFileSrcToThumbnail(show?.releaseData?.mainImage?.[0]?.src ? show?.releaseData?.mainImage?.[0] : show?.releaseData?.mainImage)}
                          alt={show.allText}
                        />
                      )}
                    </div>
                    <div className="data">
                      <div className="inside">
                        <h3>{show?.releaseData?.title}</h3>
                        <h4>{show?.releaseData?.subtitle}</h4>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          )
        ) : (
          <div className="inside">
            {shows?.map((show, key) => (
              <div className="serie" key={key}>
                <Link to={`/release/${release?._id}/show/${show._id}`}>
                  <div className="img-wrap">
                    <SideLabel licenseType={show?.releaseData?.licenseType} />
                    {(show?.releaseData?.mainImage?.[0]?.src || show?.releaseData?.mainImage.src) && (
                      <img
                        src={show?.releaseData?.mainImage?.[0]?.src || show?.releaseData?.mainImage?.src}
                        alt={show.allText}
                      />
                    )}
                  </div>
                  <div className="data">
                    <div className="inside">
                      <h3>{show?.releaseData?.title}</h3>
                      {show?.releaseData?.subtitle && (
                        <h4>{show?.releaseData?.subtitle}</h4>
                      )}
                      <div
                        className="broadcast-times-description"
                        dangerouslySetInnerHTML={{
                          __html: stripHtmlWhiteSpace(
                            show?.releaseData?.broadcastTimesDescription
                          ),
                        }}
                      ></div>
                    </div>
                    <div className="meta">
                      <div className="right">
                        {show?.releaseData?.broadcastTimes?.[0]?.dateText}
                      </div>
                      <div className="left">
                        {show?.releaseData?.specialText && (
                          <>
                            <span className="special-text">
                              {show?.releaseData?.specialText}
                            </span>
                            <span className="seperator"> | </span>
                          </>
                        )}
                        {show?.releaseData?.showType && (
                          <span className="show-type">
                            {getShowType(show?.releaseData?.showType)}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        )}
      </section>
    </main>
  );
}
