import { CURRENT_SITE } from "../data/constantData";


const prepareShow = (showData) => {
  const newShowData = { ...showData };

  // remove empty links in preview data
  if (newShowData?.preview?.links) {
    newShowData.preview.links = newShowData.preview.links.filter(
      (link) => link.src || link.link
    );
  }
  // remove empty links in live data
  if (newShowData?.releaseData?.links) {
    newShowData.releaseData.links = newShowData.releaseData.links.filter(
      (link) => link.src || link.link
    );
  }

  return newShowData;
};


const isLocal = false;
const isDev = false;
// const domain = isDev? 'http://localhost:3000' : 'http://megaphonelb-478096941.us-east-1.elb.amazonaws.com' || 'https://megaphon.co.il/api';
const domain = isDev? 'http://localhost:3000' : (isLocal? window.location.origin : 'https://megaphon.co.il/api');
// const BASE_URL = 'https://api.megaphon.co.il'; // original api server domain;
// const BASE_URL = isLocal? '/api' : `${domain}/api`;
// const OLDAPI_BASE_URL = isLocal? '/api/old-api' : `${domain}/api/old-api`;
const BASE_URL = `${domain}`;
const OLDAPI_BASE_URL = `${domain}/old-api`;

export function getRelease(releaseId) {
  // 615b09db25a07eb7191332c0
  // זאת דוגמא ללוח חודשי -
  // return fetch(`https://api.megaphon.co.il/release/${releaseId}/data`).then(
  reportReleaseOpenedForOutsourceSite(releaseId);
  return fetch(
    // `${BASE_URL}/release/${CURRENT_SITE.organization_id}/${releaseId}`
    `${OLDAPI_BASE_URL}/release/${releaseId}/data`
    , {credentials: "include"}
  )
  .then(
    (response) => response.json()
  );
}
export function getShow(showId) {
  // 60343c36ddb26d52b626967f
  // וזאת דוגמא לתוכנית (לקחתי אותה מ-״כאן״ כי לא ראיתי שיש ב-״הוט״ תוכניות עם מספיק דטה)
  // return fetch(`https://api.megaphon.co.il/release/${showId}/data`)
  // reportReleaseOpenedForOutsourceSite(showId);
  return fetch(
    // `${BASE_URL}/release/${CURRENT_SITE.organization_id}/${showId}`
    `${OLDAPI_BASE_URL}/release/${showId}/data`
    , {credentials: "include"}
  )
    .then((response) => response.json())
    .then(prepareShow);
}
export function searchFor(searchTerm) {
  // חיפוש מחזיר מערך של תוכניות:
  return fetch(
    // `https://api.megaphon.co.il/organization/${CURRENT_SITE.organization_id}/releases?releaseType=${CURRENT_SITE.releaseTypes.show}&isInEdit=false&searchTerm=${searchTerm}`
    `${OLDAPI_BASE_URL}/organization/${CURRENT_SITE.organization_id}/releases?releaseType=${CURRENT_SITE.releaseTypes.show}&isInEdit=false&searchTerm=${searchTerm}`, {credentials: "include"}
  ).then((response) => response.json());
}
export function getAllReleases() {
  // ואתה יכול להשתמש ככה בחיפוש כדי לקבל את כל הלוחות החודשיים:
  return fetch(
    // `https://api.megaphon.co.il/organization/${CURRENT_SITE.organization_id}/releases?releaseType=${CURRENT_SITE.releaseTypes.monthlyBoard}&isInEdit=false&searchTerm=`
    `${OLDAPI_BASE_URL}/organization/${CURRENT_SITE.organization_id}/releases?releaseType=${CURRENT_SITE.releaseTypes.monthlyBoard}&isInEdit=false&searchTerm=`, {credentials: "include"}
  ).then((response) => response.json());

  // return fetch(
  //   `${BASE_URL}/release/${CURRENT_SITE.organization_id}/?${getQuerysStr({
  //       "filter": {
  //           "search": "",
  //           "params": {}
  //       },
  //       "pagination": {
  //           "page": 0,
  //           // "limit": 50
  //           "limit": Number.MAX_SAFE_INTEGER
  //       },
  //       "sort": {},
  //       "simpleSort": "",
  //       "orgFilter": {
  //           "releaseTypes": [
  //               // "61c3631b403f3a8a0e4fac73"
  //               CURRENT_SITE.releaseTypes.monthlyBoard
  //           ],
  //           // "wasDistributed": false
  //       },
  //       "folder": null
  //   })}`
  // ).then(async (response) => {
  //   const res = await response.json();
  //   console.log(res);
  //   return res.items;
  // });
}

export function reportReleaseOpenedForOutsourceSite(releaseId = '') {
  function getQueryParam(param) {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get(param);
  }
  const origin = getQueryParam('origin');
  const token = getQueryParam('token');
  const releaseIdInQuery = getQueryParam('releaseId');
  if (!token) return;
  return fetch(
    `${BASE_URL}/distribution/release-opened/`
      + `?token=${token}`
      + `&origin=${origin}`
      + `&releaseId=${releaseIdInQuery || releaseId}`
      + `&isLandingPage=${true}`
  );
}



export function logToServer(level, title, msg, data) {
  try {
    return fetch(`${BASE_URL}/log`, {
      method: 'POST',
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({level, title, msg, data})
    });
  } catch(e) {
    console.error('Cant log::');
    console.error(e);
  }
}

// function getQuerysStr(filterBy = {}) {
//   var queryStr = '';
//   const vals = [];
//   for (let key in filterBy) {
//       let val = (typeof(filterBy[key]) === 'object')? 
//                           JSON.stringify(filterBy[key]) : 
//                           filterBy[key];
//       // queryStr += `${key}=${val}&`;
//       vals.push(`${key}=${val}`);
//   }
//   // return queryStr.slice(0, queryStr.length-1);
//   queryStr = vals.join('&');
//   return queryStr;
// } 