export const stripHtmlWhiteSpace = (string) => {
  return (string || "")
    .replace("<p>&nbsp;&nbsp;</p>", "")
    .replace("<p><br></p>", "")
    .replace(`<p dir="RTL" style="text-align: justify;">
	&nbsp;</p>`, "")
    .replace("<p>&nbsp;</p>", "");
};

export function htmlStrToText(htmlStr) {
  const el = document.createElement('div');
  el.innerHTML = htmlStr;
  return el.innerText;
}
