import React from "react";
import { getLicenseType } from "../fields/licenseType";

// possible classes: gradient, red, pink, black
const licenseTypeClasses = {
  מקור: "gradient",
  רכש: "black",
  "רכש ישראלי": "black long-text",
  "hbo": "hbo-label",
};

export default function SideLabel(props) {
  const { licenseType } = props; // returns an id

  const label = getLicenseType(licenseType);
  const classes = licenseTypeClasses[label] || "";

  return !label ? (
    ""
  ) : (
    <div className={`side-label ${classes}`} style={ [3, '3'].includes(licenseType)? { } : {}}>
      <div  style={ [3, '3'].includes(licenseType)? { fontSize: '12px', padding: '34px 40px 4px' } : {}} className="inside">{label}</div>
    </div>
  );
}
